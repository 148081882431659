import React, {Component} from 'react';


export default class CommentErrorBoundary extends Component {
      constructor(props) {
            super(props);
            this.state = { error: null, errorInfo: null };
          }
          
          static getDerivedStateFromError(error) {
            // Update state so the next render will show the fallback UI.
            return { error: true };
          }
      componentDidCatch(error, errorInfo) {
            this.setState({
                  error: error,
                  errorInfo: errorInfo
                })
      }

      render() {
            if(this.state.errorInfo){

                  return (
                        <div style={{height:'350px',overflowY:'scroll'}}>
                              <h2>Something went wrong.</h2>
                              <details style={{ whiteSpace: 'pre-wrap' }}>
                                   {`There has been an error rendering a comment`}
                                   <br/>
                                
                                    {this.state.error && this.state.error.toString()}
                                    <br />
                                    {this.state.errorInfo.componentStack}
                              </details>
                        </div>
                  )
                  }
            return this.props.children
            
      }
}