export default {
    entryManifestURL: `${process.env.REACT_APP_EDITION_DATA_URL}/entries.json`,
    entries: null,
    entryList: null,
    filterTags: [],
    filterCategories: [],
    categoryNames: [
        "alchemy",
        "animal husbandry",
        "arms and armor",
        "casting",
        "corrosives",
        "cultivation",
        "decorative",
        "dyeing",
        "glass process",
        "household and daily life",
        "La boutique",
        "lists",
        "manuscript structure",
        "medicine",
        "merchants",
        "metal process",
        "painting",
        "practical optics",
        "preserving",
        "printing",
        "stones",
        "tool",
        "tricks and sleight of hand",
        "varnish",
        "wax process",
        "wood and its coloring"
    ],
    tagNameMap: { 
        "al": "animal",
        "wp": "arms and armor",
        "bp": "bodypart",
        "cn": "currency",
        "df": "definition",
        "env": "environment",
        "de": "german",
        "el": "greek",
        "it": "italian",
        "la": "latin",
        "m": "material",
        "ms": "measurement",
        "md": "medical",
        "mu": "music",
        "oc": "occitan",
        "pn": "personal name",
        "pl": "place",
        "pa": "plant",
        "po": "poitevin",
        "pro": "profession",
        "sn": "sensory",
        "tmp": "temporal",
        "tl": "tool",
    },
    loaded: false
}	