import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: { 
        main: '#792421',
    },
    secondary: {
      main: '#EBE3DD',
    }
  },
})
export default theme