import * as React from "react"

function SideMenuIconLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={176.728}
      height={165}
      viewBox="0 0 176.728 165"
      {...props}
    >
      <g
        data-name="Component 14 \u2013 1"
        fill="none"
        stroke={props.color ? props.color : "#000"}
        strokeLinecap="round"
        strokeWidth={18}
      >
        <path data-name="Line 129" d="M25 42h43.366" />
        <path data-name="Line 133" d="M9 9h90" />
        <path data-name="Line 134" d="M25 122h43.366" />
        <path data-name="Line 135" d="M25 82h43.366" />
        <path data-name="Line 136" d="M99 156V9" />
        <path data-name="Line 137" d="M9 156h90" />
        <path data-name="Line 138" d="M164 112l-32-32" />
        <path data-name="Line 139" d="M164 50l-32 30" />
      </g>
    </svg>
  )
}

export default SideMenuIconLeft