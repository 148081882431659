import * as React from "react"

function NaIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="5.5 -3.5 64 64"
      {...props}
    >
      <circle fill="transparent" cx={37.47} cy={28.736} r={29.471} />
      <path fill='white' d="M37.442-3.5c8.99 0 16.571 3.085 22.743 9.256C66.393 11.928 69.5 19.509 69.5 28.5c0 8.992-3.048 16.476-9.145 22.458C53.88 57.32 46.241 60.5 37.442 60.5c-8.686 0-16.19-3.162-22.513-9.485C8.644 44.728 5.5 37.225 5.5 28.5c0-8.762 3.144-16.343 9.429-22.743C21.1-.414 28.604-3.5 37.442-3.5zM12.7 19.872c-.952 2.628-1.429 5.505-1.429 8.629 0 7.086 2.59 13.22 7.77 18.4 5.219 5.144 11.391 7.715 18.514 7.715 7.201 0 13.409-2.608 18.63-7.829 1.867-1.79 3.332-3.657 4.398-5.602l-12.056-5.371c-.421 2.02-1.439 3.667-3.057 4.942-1.622 1.276-3.535 2.011-5.744 2.2v4.915h-3.714v-4.915c-3.543-.036-6.782-1.312-9.714-3.827l4.4-4.457c2.094 1.942 4.476 2.913 7.143 2.913 1.104 0 2.048-.246 2.83-.743.78-.494 1.172-1.312 1.172-2.457 0-.801-.287-1.448-.858-1.943L37.9 31.127l-3.771-1.715-5.086-2.229L12.7 19.872zM37.557 2.214c-7.276 0-13.428 2.571-18.457 7.714a30.623 30.623 0 00-3.543 4.287L27.786 19.7c.533-1.676 1.542-3.019 3.029-4.028 1.484-1.009 3.218-1.571 5.2-1.686V9.071h3.715v4.915c2.934.153 5.6 1.143 8 2.971l-4.172 4.286c-1.793-1.257-3.619-1.885-5.486-1.885-.991 0-1.876.191-2.656.571-.781.381-1.172 1.029-1.172 1.943 0 .267.095.533.285.8l4.057 1.83 2.8 1.257 5.144 2.285 16.397 7.314a29.51 29.51 0 00.801-6.857c0-7.353-2.552-13.543-7.656-18.573-5.067-5.143-11.241-7.714-18.515-7.714z" />
    </svg>
  )
}

export default NaIcon
